.spinner-circle-mod img {
    position: absolute;
    left: 44.9%;
    top: 20%;
}

.rw-combobox input{
    border-bottom: none !important;
  }
.rw-combobox.rw-state-focus input[type='text']:focus{
    border-bottom: none !important;
    box-shadow: none !important;
}
.combo-input.rw-state-focus>div{
    box-shadow: none !important;
}

@media only screen and (max-width: 1365px) {
    .spinner-circle-mod img {
        left: 44.5%;  
        top: 20%;
    }
}

@media only screen and (min-width: 1400px) {
    .spinner-circle-mod img {
        left: 45.7%;  
        top: 20%;
    }
}

.rw-combobox input{
    border-bottom: none !important;
  }
.rw-combobox.rw-state-focus input[type='text']:focus{
    border-bottom: none !important;
    box-shadow: none !important;
}
.combo-input.rw-state-focus>div{
    box-shadow: none !important;
}