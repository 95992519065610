.MuiTypography-body1-84 {
    font-size: 14px !important;
}
.TableList-chip-7 {
    margin: 8px;
    margin-left: 0 !important;
}
.custom-form-grp.row.clearfix .col-sm-11 div:first-child, .custom-form-grp.row.clearfix .col-sm-12 div:first-child {
    margin-bottom: 14px;
}
.custom-form-grp select.form-control {
    width: 98%;
    border: 2px solid #f5f5f5 !important;
    padding: 5px 7px;
    height: 42px;
}
table tr .jss204.jss7 {
    margin-left: 0;
}
table tr td div {
    margin-left: 0;
}
.sidebar .nav {
    width: 200px;
    flex-direction: column;
    min-height: auto !important;
    padding: 0;
}
.for-hide-fields.wd-1 .dropdown-menu{
    z-index: 1 !important;
    width: 664px !important;
  }
.table-responsive .table .action-buttons {
    min-width: 90px !important;
}
  
.new-signup.login-bg.bg2 .main {
    background: transparent!important;
}

.new-signup .orange-main img {
    width: 250px!important;
    margin: 0px auto;
}


/***registration css update***/
header.custom-header.for-new-registration {
    position: fixed;
    top: -22px;
    z-index: 9;
    width: 100%;
}
.custom-header.for-new-registration a.nav-brand img {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    padding: 2px 16px;
    height: auto !important;
}

main.main.registration-update {
    height: 100vh !important;
}

.new-signup ul.signup-fl li:before{display: none;}
.orange-main.register-style-left .reglist-icon {
    width: 22px;
    display: inline-block;
    margin-right: 12px;
    vertical-align: middle;
    position: relative;
    top: -3px;
}

.orange-main.register-style-left .reglist-icon img {
    width: 100% !important;
}
.new-signup .register-style-left .o-heading h4 {
    font-size: 36px;
    color: #fff !important;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 0;
    font-family: 'Jost', sans-serif !important;
    text-align: left;
    position: relative;
}
.new-signup ul.signup-fl li {
    margin-bottom: 13px;
    position:
     relative;
    font-size: 17px;
    color: #fff;
    font-size: 18px;
}
.new-signup .register-style-left .o-heading h4:before {
    position: absolute;
    bottom: -13px;
    left: 0;
    width: 139px;
    height: 4px;
    content: "";
    background: #23a2cd;
}

.new-signup .register-style-left ul.signup-fl {
    padding-left: 0;

}
.new-signup .register-style-left ul.signup-fl li {
    margin-bottom: 15px;
    position: relative;
}
.orange-main.register-style-left {
    width: 70% !important;
    position: fixed;
    height: 100vh !important;
    padding: 13% 20% 0px 75px !important;
    background: url(/assets/img/registration-bg1.png) no-repeat !important;
    background-size:100% !important;
    width: 70%;
}

.new-signup.login-bg.bg2 .registration-update .form-content {
    margin-left: 71% !important;
    /* position: fixed !important; */
    padding: 0px 22px 202px;
    background: #fff !important;
    /* box-shadow: none !important; */
    height: auto !important;
}
.showTooltip-fontSize{
    font-size: 16px !important;
}
@media (min-width:1600px)
{
    .new-signup.login-bg.bg2 .registration-update .form-content {
        /* padding: 0px 22px 0px !important; */
        /* margin-left: 1340PX !important;
        height: 101vh !important; */
    }
    .showTooltip-fontSize{
        font-size: 16px !important;
    }
}
.registration-update .after-reg-steps label.custom-label {
    color: #000 !important;
}
.new-signup .registration-update .btn-custom button.btn {
    font-size: 16px;
    background: #23a2cd;
    border-radius: 5px;
    padding: 8px 4px;
}
.gradient-overlay {
    position: absolute;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#267dba+0,23a3ce+37,1fcde4+100 */
background: #267dba; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #267dba 0%, #23a3ce 37%, #1fcde4 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  #267dba 0%,#23a3ce 37%,#1fcde4 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  #267dba 0%,#23a3ce 37%,#1fcde4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#267dba', endColorstr='#1fcde4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;opacity: .6;
    
}
.o-heading {
    position: relative;
    z-index: 9;
}

.new-signup.login-bg.bg2 .registration-update .main-frame {
    margin-top: 76px !important;
}
.registration-update .mdc-form span {
    position: absolute;
    bottom: -3px;
    font-size: 12px;
    left:0px;
}
.registration-update .mdc-form {
    position: relative !important;
}
.registration-update .mdc-form input {
    margin-bottom: 15px;
}
.new-signup.login-bg.bg2 .registration-update span.site-text {
    position: relative;
    top: 3px;
}
.registration-update .step2.steps.clearfix {
    margin-top: 166px !important;
}
.registration-update .step3.steps.progress-screen.clearfix {
    margin-top: 135px !important;
}
.registration-update .steps.final-step.efl-main{ margin-top: 135px !important;}
.new-signup .registration-update .loading-progress img {
    width: 14%;
    margin: 53px auto;
    /* display: inline-table; */
}
.new-signup.login-bg.bg2 .setup-progress h4 {
    font-family: 'Jost', sans-serif !important;
    font-weight: 500;
}
.otp-overlay {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0,0,0,.7);
    bottom: 0;
    z-index: 99;
}
.otp-content {
    width: 600px;
    margin: 0px auto;
    background: #fff;
    margin-top: 85px;
    border-radius: 5px;
    padding: 20px;
}
.otp-content .mdc-form.clearfix {
    width: 100%;
}
.otp-content .text-center.btn-custom {
    margin-top: 14px;
}
.otp-content .btn-custom button.btn {
    font-size: 14px !important;
    background: #23a2cd;
    border-radius: 5px;
    min-width: 120px !important;
    padding: 6px 4px !important;
    margin: 0 10px;
}

@media all and (max-width:1368px)
{
    .new-signup.login-bg.bg2 .registration-update .main-frame {
        margin-top: 74px !important;
    }
}
.app-header.navbar .navbar-brand img {
    width: 100%  !important;
    height: auto !important;
}