.upload .jumbotron {
    padding: 7px 16px;
    border: thin solid #e2e2e2;
    border-radius:4px;
}
.jumbotron {border-color: #e2e2e2 !important;}

/* .main .container-fluid {
    padding: 0 0px!important;
} */
.upload .tablink {cursor: pointer;}
.tablink.active{border-bottom:thin solid #28a745}
.listPad{padding:5px!important;margin:2px!important;}
.videoList{padding:10px 25px}

.player-wrapper {
    margin-top:2px;
    margin-bottom:2px;
    position: relative;
   
  }
   
  .react-player {
    position: absolute;
    top: 2%;
    bottom:2%;
    left: 0;
    background: #333;
  }
  .videoList .react-player {
    min-height: auto !important;
}
.videoList .vcol .react-player {
    min-height: 280px !important;
}
.videoList .vcol .react-player iframe {
    min-height: 280px;
}
.videoList .react-player div {
    height: auto !important;
}
.videoList .vcol .react-player {
    min-height: 70px !important;
}
  .fb_iframe_widget_fluid_desktop iframe {
    min-width: auto!important;
    position: relative;
}
.react-player__preview {
    min-height: 70px;
}
.videoactive .react-player{border: 4px solid #007bff!important;overflow: hidden;}
.videotitle{font-size: 12px}
.videopreview{margin-bottom: 10px}
.vlist{margin-top:5px; z-index: 2!important; opacity: 0.9; cursor: pointer;}
.vcol{padding-right:5px!important; padding-left:5px!important}
.embed{padding:4px 2px; text-align: right; font-size:13px; color:#20a8d8;cursor: pointer;}
.imageactive{border: 3px solid #007bff!important;overflow: hidden;}
/* Image Component Css */


.image-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden!important;
    padding-right: 5px!important;
    padding-left: 5px!important;
    }
  
    .image-wrapper img{
    flex-shrink: 0;
    width:100%;
    height:50px
    }
    .vlist .player-wrapper {
      z-index:-1!important
    }
    



/* audio componenet css */
audio{width:100%}
.audio{margin-bottom:5px;}
.spantitle{font-size:12px;color:#007bff;padding:10px;}
.audio {
    margin: 10px 6px;
    margin-bottom: 0;
}
.tab-content .btn {
    background: #fff !important;
    color: #787878;
    border-radius: 50px;
    min-width: 90px;
    margin-left: 9px;
    border-color: #c4c4c4;
}
.tab-content .btn:hover {
    color: #f45d59;
    border-color: #f45d59;
}
button.min-btn.pull-right.btn.btn-secondary {
    background: transparent;
    color: #c4c4c4;
    border: 1px solid #c4c4c4 !important;
    min-width: 102px;
    border-radius: 50px;
}
button.min-btn.pull-right.btn.btn-secondary:hover{color:#f45d59; border-color:#f45d59 !important}
.treeJumbo.jumbotron {padding: 9px 16px;padding-bottom: 24px;min-height: 220px;}
li.rc-tree-treenode-switcher-close {background: #f5f5f5;border-radius: 4px;margin-bottom: 10px !important;}
h4.subCatHeading {font-size: 14px;margin: 10px 10px;}
.cf-Btn {text-align: right;}
.cf-Btn .btn {margin-left: 11px;}
.addCity {position: relative;top: -40px;}