$enable-gradients: true;
$enable-shadows: true;
$enable-transitions: true;
$theme-colors: ("cyan": #ff7885,
  "lb":#a1d0fc, "lg" :#78c85b, "ly" :#f9d957, "db": #4c536d, "lpurple": #a277dd,
  "bg": #51c1c1, "mb": #7677c9, "lpink": #ef7f91, lgrey: #eee);
$border-width2: 2px;
$fa-font-path: "./fontawesome/webfonts";


$select-input-height: 20px;
$border-width2: 2px;
$sizes: (
  5: 5%,
  10: 10%,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%);

@import 'bootstrap/scss/bootstrap.scss';