.sidebar-lg-show.sidebar-fixed .bot-frame.asc-bot-v2.upload-btn.show-bot.chat-widget-flow #resizable {
    width: auto !important;
    width: 100% !important;
    width: calc(100% - 47%) !important;
    margin-left: 46% !important;
    margin-top: calc(0% + 1px) !important;

}
.asc-bot-v2.chat-widget-flow .wc-chatview-panel {
    width: calc(100% - 3%) !important;
    position: relative !important;
    right: 0 !important;
    margin-left: 0% !important;
    padding-right: 0 !important;
    margin-top: calc(0% + 236px) !important;
    height: 100% !important;
}
.bot-frame.asc-bot-v2.chat-widget-flow .wc-header {
    background: #ffff !important;margin-top:0px !important;}

    .bot-frame.asc-bot-v2.chat-widget-flow .wc-message-groups {
        background-color: #fff !important;
    }
    .bot-frame.asc-bot-v2.chat-widget-flow .wc-chatview-panel {
       
        box-shadow: none !important;
        border-radius: 3px !important;
    }
    .bot-frame.asc-bot-v2.chat-widget-flow .wc-console {
      
        background: #fff !important;
    }
    .asc-bot-v2.chat-widget-flow #resizable .wc-chatview-panel {
        border-radius: 14px;
        height: 584px !important;
        width: calc(100% - 47%) !important;
    }
    .sidebar-fixed .bot-frame.asc-bot-v2.upload-btn.show-bot.chat-widget-flow #resizable {
       
        width: calc(100% - 36%) !important;
        margin-left: 35% !important;
        margin-top: calc(0% + 1px) !important;
    }
    .sidebar-lg-show.sidebar-fixed.arrow-left-right .bot-frame.asc-bot-v2.upload-btn.show-bot.chat-widget-flow #resizable {
      
        width: calc(100% - 20%) !important;
        margin-left: 20% !important;
      
    }



    /* full widget */
    .asc-bot-v2.chat-widget-full.chat-widget-flow #resizable .wc-chatview-panel {
        border-radius: 14px;
        height: calc(100% - 28%) !important;
    }
    .sidebar-lg-show.sidebar-fixed .bot-frame.asc-bot-v2.upload-btn.show-bot.chat-widget-full.chat-widget-flow #resizable {
        width: auto !important;
        width: 100% !important;
        width: calc(100% - 243px) !important;
        margin-left: 243px !important;
       
    }
    .bot-frame.asc-bot-v2.chat-widget-full.chat-widget-flow #resizable .wc-chatview-panel {
        max-height: 100% !important;
        width: calc(100% - 260px) !important;
        height: calc(100% - 237px) !important
    }
    .bot-frame.asc-bot-v2.chat-widget-full.chat-widget-flow #resizable .wc-chatview-panel {
        max-height: 100%!important;
        width: calc(100% - 5px)!important;
        height: calc(100vh - 108px) !important;
        margin-top: 144px !important;
    }
    .bot-frame.asc-bot-v2.chat-widget-full.chat-widget-flow .wc-message-groups {
        background-color: #f5f5f5!important;
    }
    .bot-frame.asc-bot-v2.chat-widget-full.chat-widget-flow .wc-header {
        background: #f5f5f5!important;
    }
    .menuItem {
        text-align: center;
    }