.public-DraftStyleDefault-orderedListItem {
	margin-left: 1em !important;
	/* list-style-type: unset!important; */
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
	left: -10px !important;
	top: 0px !important;
	text-align: justify !important;
	font-size: 12px !important;
}
.public-DraftStyleDefault-orderedListItem:before {
	/* content: unset!important;
    counter-increment: unset!important; */
}

.public-DraftStyleDefault-block {
	padding: 1px 5px !important;
	font-size: inherit;
	margin: 0em !important;
}
.rdw-editor-main {
	padding: 5px !important;
}
.modal-content label {
	font-size: 12px;
	word-break: break-all !important;
}
.rdw-option-wrapper {
	font-size: 13px;
}
.rdw-image-modal {
	padding: 10px;
}
.rdw-image-modal-header {
	font-size: 14px;
}
.rdw-image-modal-btn {
	padding: 2px 6px;
	font-size: 14px;
	color: #333;
}
.btnupload {
	text-align: center !important;
}
.btnupload button {
	background: #eaeaea !important;
}
.editor-icon {
	color: #333;
}
.editor-icon .fa.fa-paperclip {
	transform: rotate(135deg);
	font-size: 19px;
}
