.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    background: #fff;
    padding: 3px 0;
    border: 1px solid #e2e2e2;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #787878;
    background-color: #fff;
    border-color: transparent;
}
.sidebar-fixed .nav-tabs a.nav-link {
    color: #787878;
}
ul.nav.nav-tabs {
    padding-bottom: 0px;
}
.nav-tabs a.active.nav-link {
    border-color:transparent;
    border-bottom: 3px solid #7986cb !important; color:#7986cb !important; transition: all .4s;font-weight:600;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{ border-color:transparent !important; color:#7986cb !important;transition: all .4s;}
.nav-tabs a.active.nav-link:hover{color:#7986cb !important; transition: all .4s;}

.nav-tabs .nav-item {
    min-width: 130px;
    text-align: center;
}
.nav-tabs {
  
    border: transparent;
}
.tab-content {
 
    border: transparent;
    border-top: 1px solid #e2e2e2;
}
.tab-content .v-grid.col-sm-12 h3 {
    font-size: 18px;
    margin-bottom: 15px;
}
.sidebar-fixed .nav-tabs a.nav-link {
    font-size: 14px;
}
.navHideN{
    background: #8E8CD8;
    color: #fff;
    height: 41px;
    display: inline-block;
    width: 21px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 7px 8px;
    font-size: 18px;
    position: absolute;
    z-index: 4;
    left: -9px; cursor: pointer;
}

.note-type .custom-control {padding-left: 0!important;}
.notelisting {margin-bottom: 1rem;}
.notelisting p{margin-bottom: 0!important;}
.chatscript{    background: #fff !important;
    border: 0px !important;
    border-radius: 4px !important;
    font-size: 14px;
    color: #787878 !important;
    padding: 10px 0px;
    text-indent: 8px;
    width: 100%;}